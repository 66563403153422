import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

export const useStream = () => {
  const stream = ref<MediaStream | undefined>();
  const router = useRouter();

  const facingMode = ref<'environment' | 'user'>('environment');
  const streamConstraints = computed<MediaStreamConstraints>(() => ({
    video: { facingMode: facingMode.value },
    audio: true,
  }));

  const initStream = async () => {
    try {
      stream.value = await navigator.mediaDevices.getUserMedia(streamConstraints.value);
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Dalej nie przejdziesz! 🧙 (bez kamery) ');
      router.push('/');
    }
  };

  const closeStream = () => {
    if (stream.value) stream.value.getTracks().forEach((t) => t.stop());
  };

  const toggleFacingMode = async () => {
    closeStream();
    facingMode.value = facingMode.value === 'user' ? 'environment' : 'user';
    stream.value = await navigator.mediaDevices.getUserMedia(streamConstraints.value);
  };

  onMounted(() => {
    initStream();
  });

  onBeforeUnmount(() => {
    closeStream();
  });

  return {
    stream,
    toggleFacingMode,
  };
};
