import {
  defineComponent,
  onMounted,
  onUnmounted,
  toRef,
  watch,
} from 'vue';

export default defineComponent({
  props: {
    duration: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['tick'],
  setup(props, { emit }) {
    let intervalId: NodeJS.Timer;

    const tick = () => {
      if (props.active) emit('tick');
    };
    const start = () => {
      intervalId = setInterval(tick, props.duration);
    };

    onMounted(async () => {
      tick();
      watch(toRef(props, 'active'), (active) => {
        if (active) {
          start();
        } else {
          clearInterval(intervalId);
        }
      }, { immediate: true });

      watch(toRef(props, 'duration'), () => {
        if (props.active) {
          clearInterval(intervalId);
          start();
        }
      });
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    return () => null;
  },
});
