<template>
  <div
    v-if="!isSoundOn"
    class="w-full h-screen flex items-center justify-center text-center text-gray-500"
  >
    <AButton
      large
      @click="initWatcher"
    >
      Rozpocznij transmisję
      <IconPlay class="h-4 ml-1 inline-block"/>
    </AButton>
  </div>
  <ListenerPeer
    v-if="stream"
    :id="id"
    :key="`${namespace}-${listenerPeerInstanceKey}`"
    recipientId="listener"
    :recipientPublicKey="listenerPublicKey"
    :namespace="namespace"
    :stream="stream"
    @close="createNewPeer"
    @data="handlePeerMessage"
  >
    <template v-slot="{ connected, ready }">
      <TimeInterval
        :duration="2000"
        :active="ready && !connected"
        @tick="sendWatcherRequest"
      />
      <div class="w-full h-screen flex items-center justify-center text-center text-gray-500">
        {{ connected ? 'połączono 📷' : 'łączenie...'}}
      </div>
    </template>
  </ListenerPeer>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import NoSleep from 'nosleep.js';
import {
  PlayCircleRegular as IconPlay,
} from '@vicons/fa';
import { useChannel } from '@/services/api/privateChannel';
import { NoiseStatusMessage, useNoise } from '@/services/noise';
import { useStream } from '@/services/stream';
import { WatcherRequestEvent } from '@/services/api/types';
import AButton from '@/components/AButton.vue';
import ListenerPeer from '@/components/Peer';
import TimeInterval from '@/components/TimeInterval';

export default defineComponent({
  components: {
    AButton,
    IconPlay,
    ListenerPeer,
    TimeInterval,
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { id, publicKey, connection } = useChannel({ namespace: props.namespace });
    const listenerPublicKey = ref<string | undefined>();
    const listenerPeerInstanceKey = ref<number>(0);
    const noSleep = new NoSleep();

    const {
      startNoise,
      stopNoise,
      setVolume,
      setType,
    } = useNoise();

    const { stream, toggleFacingMode } = useStream();

    const isSoundOn = ref(false);

    const initWatcher = () => {
      noSleep.enable();
      setVolume(-Infinity);
      startNoise();
      isSoundOn.value = true;
      setTimeout(() => {
        stopNoise();
      });
    };

    const handlePeerMessage = (message: string) => {
      const noiseMessageMatch = message.match(/^noise\|(.*)$/);
      if (noiseMessageMatch) {
        const payload = JSON.parse(noiseMessageMatch[1]) as NoiseStatusMessage;
        if (!payload.active) {
          stopNoise();
          return;
        }
        setVolume(payload.volume);
        setType(payload.type);
        if (payload.active) startNoise();
      }
      if (message === 'camera|toggle') {
        toggleFacingMode();
      }
    };

    const createNewPeer = () => {
      if (isSoundOn.value) stopNoise();
      listenerPeerInstanceKey.value += 1;
    };

    const sendWatcherRequest = async () => {
      if (!connection.socket.value || !publicKey.value) return;
      const event: WatcherRequestEvent = {
        id,
        publicKey: publicKey.value,
      };
      const response = await connection.socket.value.call('register-watcher', event) as { status: string, ownerPublicKey: string };
      if (response.status === 'ok') {
        listenerPublicKey.value = response.ownerPublicKey;
      }
    };

    onMounted(() => {
      document.documentElement.style.backgroundColor = 'black';
    });

    onUnmounted(() => {
      document.documentElement.style.backgroundColor = '';
      if (noSleep) noSleep.disable();
    });

    return {
      id,
      createNewPeer,
      listenerPeerInstanceKey,
      sendWatcherRequest,
      listenerPublicKey,
      handlePeerMessage,
      startNoise,
      stopNoise,
      isSoundOn,
      initWatcher,
      stream,
    };
  },
});
</script>
